.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Spacing-top {
  padding-top: 1.5em;
}

/* .Spacing-row {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.Spacing-column {
  height: 3rem;
} */

/* .Spacing-label {
  padding-top: 1rem;
  padding-bottom: 1rem;
} */

.Background-input {
  background-color: #f2f2f2;
}

.Background-comparison {
  background-color: darkslategrey;
}

.Color-white {
  color: white;
}

.ui.header {
  font-weight: 200 !important;
}
